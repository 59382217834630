import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Button, Tooltip, Checkbox, Input, Select, FormControl, TextField, Menu, MenuItem,
    Typography, IconButton, Box, Slide, Container, Grid, Switch
} from '@material-ui/core';
import MORE_APPS_CONFIG from '../../moreApps';
//import MyEditor from './MyEditor'

import CustomEditor from './CustomEditor';

import CloseIcon from '@material-ui/icons/Close';
import http from 'axios';
import axios from 'axios';

const BootstrapDialog = makeStyles((theme) => ({
    dialogClass: {

        '& .MuiDialog-paperScrollPaper': {
            maxHeight: 'none'
        },
        '& .MuiDialog-paper': {
            margin: '0px'
        },
        '& .MuiDialog-scrollPaper': {
            justifyContent: 'end',
            display: 'flex',
            alignItems: 'center'

        },
        '& .MuiDialog-paperWidthSm': {
            minWidth: '679px',
            height: '100%',
            flexDirection: 'column',
            display: 'flex',
            boxShadow: 'none'

        },
        '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(52, 64, 84, 0.70)'
        }
    },
   
    materialUISwitch: {

        '& .MuiSwitch-thumb': {
            width: '16px',
            height: '16px',
            color: '#ffffff',
            border: '1px solid #2C6EF2',
            borderRadius: '2px',
            boxShadow: 'none',
            marginTop: '2px'

        },
        '& .MuiSwitch-track': {
            backgroundColor: '#BED3E4',
            opacity: 1,
            // border: '1px solid #2C6EF2',
            borderRadius: '2px',
            height: '15px',
            width: '32px'
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#2C6EF2',
            opacity: 1
        }
    },
    checkboxColor: {
        "&.MuiCheckbox-colorPrimary.Mui-checked": {
            color: '#2C6EF2'
        }
    },
    moreSettingsButton: {
        "&:hover": {
            backgroundColor: 'transparent',

        }
    },
    inputBoxUnderline: {
        '& .MuiInput-underline:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: 'none',
        },
        '& .MuiInput-root:before': {
            borderBottom: 'none',
        },
        '& .MuiInput-root:after': {
            borderBottom: 'none',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):after': {
            borderBottom: 'none',
        },
        '& .MuiInput-underline:visited:not(.Mui-disabled):after': {
            borderBottom: 'none',
        },
        '& .MuiInputBase-root-MuiInput-root:after': {
            borderBottom: 'none',
        }
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export default function DefaultScheduleSettings(props) {
    const [open, setOpen] = React.useState(true);
    const [showMore, setShowMore] = React.useState(false);
    const [closeSelectTag, setCloseSelectTag] = useState(true)
    const customDialogClass = BootstrapDialog();
    const [isCustomBtCount, setIsCustomBtCount] = useState(false);
    const [showInput, setShowInput] = useState(false);
    const [showRemainingWarnCheck, setShowRemainingWarnCheck] = useState(false);
    const [optionalFields,setOptionalFields]=useState({});
    const [isNewSchedule,setIsNewSchedule]=useState(props.isNewSchedule)
    const [clientTableData,setClientTableData]=useState({})
    const [appId,setAppId]=useState(props.selectedAppData.id);
    const [snackbarProps, setSnackbarProps] = useState({ open: false, message: "", variant: "" })
    const [mappingTableData,setMappingTableData]=useState({})

    const [defaultScheduleSettings,setDefaultScheduleSettings]=useState({
        webProctoring: {
			enabled: false,
            count:0,
            showRemainingCounts:false
		},
		scheduleType: "AlwaysOn",
		access: {
			type: "OpenForAll"
		},
		testGradeNotification: {
			enabled: false,
			recipients: null
		},
		visualProctoring: {
			options: {
				candidateScreenCapture: false,
				candidateAuthorization: false,
                check360:false,
                mobileProctoring:false,
			},
			mode: "OFF"
		},
		secureBrowser: {
			enabled: false
		}
	
    });

    const [scheduleSettingsObj,setScheduleSettingsObj]=useState({
        emailHeader:"",
        emailFooter:"",
        testExpiryLimitInSec:null,
        callBackUserName:"",
        callBackPassword:"",
        partnerClientId:"",
        defaultScheduleSettings:"",
        clientData:{},
        mappingData:{}
    })

    const MenuProps = {
        PaperProps: {
            scroll: 'body',
            style: {
                maxHeight: '153px',
                width: '344px',
            },
        },

    };


    useEffect(()=>{

        var fieldsArray=props.selectedAppData.configFieldsJson.fields.filter((val)=>{
            return val.mandatory==="false"
        })


        setOptionalFields(fieldsArray)
        if(!isNewSchedule){
            let defaultSettings=JSON.parse(props.updateData.defaultScheduleSettings)
            if (defaultSettings.webProctoring == null) {
                defaultSettings.webProctoring = {
                    enabled: false,
                    count:0,
                    showRemainingCounts:false
                }
            }
            setDefaultScheduleSettings(defaultSettings);
            checkIfCustomBtCount(defaultSettings.webProctoring?.count);
            setClientTableData(props.updateData.clientData);
            setMappingTableData(props.updateData.mappingData)
            setScheduleSettingsObj((prevState)=>{
                return({
                    emailFooter:props.updateData.emailFooter,
                    emailHeader:props.updateData.emailHeader,
                    testExpiryLimitInSec:props.updateData.testExpiryLimitInSec
                })
            })

        }
    },[isNewSchedule]);

    const handleClose = () => {
        setOpen(false);
        props.closeButtonClicked();
       // props.closeDefaultSettings()
    };

    const onClickProceed = () => {
        console.log("inside proceed button")
        setOpen(false);
    }

    const onCloseSelectTag = (event) => {
        setCloseSelectTag(true)
    }

    const onOpenSelectTag = (event) => {
        setCloseSelectTag(false)
    }

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setShowInput(false)
        let showRemainingWarnCheckBox = false
        // setChecked(event.target.checked);

        if (name === "visualProctoring") {
           
            setDefaultScheduleSettings((prevState) => {

                return ({
                    ...prevState,
                    visualProctoring: {
                        mode: checked ? "PHOTO" : "OFF",
                        options: {
                            candidateAuthorization: false,
                            candidateScreenCapture: false,
                            check360: false,
                            mobileProctoring:false
                        }
                    },
                    secureBrowser:{
                        enabled:false
                    }
                })
            })
        }
        if (name === "recordVideo") {
           
            setDefaultScheduleSettings((prevState) => {

                return ({
                    ...prevState,
                    visualProctoring: {
                        mode: checked ? "VIDEO" : "PHOTO",
                        options: {
                           ...prevState.visualProctoring.options
                        }
                    }
                })
            })
        }
        if (name === "candidateAuthorization" || name==="candidateScreenCapture"||name==="check360"||name==="mobileProctoring") {
           
            setDefaultScheduleSettings((prevState) => {

                return ({
                    ...prevState,
                    visualProctoring: {
                        mode: defaultScheduleSettings.visualProctoring.mode ,
                        options:{
                            ...prevState.visualProctoring.options,
                            [name]:checked
                        }
                    }
                })
            })
            console.log("check==",defaultScheduleSettings.visualProctoring)
        }
        if (name === "secureBrowser") {
           
            setDefaultScheduleSettings((prevState) => {

                return ({
                    ...prevState,
                   secureBrowser:{
                    enabled:checked
                   }
                })
            })
        }
        if(name==="testExpiryLimitInSec"||name==="emailHeader"||name==="emailFooter"){
            setScheduleSettingsObj((prevState) => {
                
                return ({
                    ...prevState,
                [name]:value
                })
        })
            console.log("scheduleObj==>",scheduleSettingsObj)
        }

        if (name === "browsingToleranceCount") {
            //setBrowsingToleranceCount(value)
            checkIfCustomBtCount(value);
            if (name === "browsingToleranceCount" && value == 11) {
                setShowInput(true)
            }
            if (name === "browsingToleranceCount" && value > 0) {
                showRemainingWarnCheckBox = true

            }

            setDefaultScheduleSettings((prevState) => {

                return ({
                    ...prevState,
                    webProctoring: {
                        count: value === "" ? 0 : value,
                        enabled: value === "" ? false : true,
                        showRemainingCounts: defaultScheduleSettings.webProctoring.showRemainingCounts
                    },
                })
            })

        }

        if (name === "remainingCountEnabled") {

            setDefaultScheduleSettings((prevState) => {

                return ({
                    ...prevState,
                    webProctoring: {
                        count: defaultScheduleSettings.webProctoring.count,
                        enabled: defaultScheduleSettings.webProctoring.enabled,
                        showRemainingCounts: checked
                    },
                })
            })
        }
        setShowRemainingWarnCheck(showRemainingWarnCheckBox)
    }

    const checkIfCustomBtCount = (value) => {
        const defaultOptions = ["", 0, 1, 2, 4, 10];
        let val = !defaultOptions.includes(parseInt(value)) && value !== "" ? true : false
        setIsCustomBtCount(val)

    }

    const handleCustomWarningChange = (event) => {
        let { name, value, min, max } = event.target;
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        if (name === "customWarning") {

            // setBrowsingToleranceCount(value)

            setDefaultScheduleSettings((prevState) => {

                return ({
                    ...prevState,
                    webProctoring: {
                        count: value,
                        enabled: defaultScheduleSettings.webProctoring.enabled,
                        showRemainingCounts: defaultScheduleSettings.webProctoring.showRemainingCounts
                    },
                })
            })

        }
    }

    const handleChangeDynamicFields=(event,tableName)=>{
        let { name, value, min, max } = event.target;
        if(tableName==="client"){
            setClientTableData((prevState) => {

                return ({
                    ...prevState,
                [name]:value
                })
        })
        
        console.log("clientTable==>",clientTableData)
        }
        if(tableName==="client_app_mapping"){
            setMappingTableData((prevState) => {

                return ({
                    ...prevState,
                [name]:value
                })
        })

       
        console.log("mappingTable==>",mappingTableData)
    }
    }

    const saveConfiguration=()=>{

        // var bodyFormData = new FormData();
        // bodyFormData.set('assessmentSetting', JSON.stringify(defaultScheduleSettings));
        // bodyFormData.set('clientAppMappingTable',JSON.stringify( mappingTableData));
        // bodyFormData.set('clientTable', JSON.stringify(clientTableData));
        if(Object.keys(props.mandatoryFields).length!=0){

        }

        let scheduleSettings={
            defaultScheduleSettings:JSON.stringify(defaultScheduleSettings),
            mappingData:mappingTableData,
            clientData:clientTableData,
            testExpiryLimitInSec:scheduleSettingsObj.testExpiryLimitInSec,
            emailFooter:scheduleSettingsObj.emailFooter,
            emailHeader:scheduleSettingsObj.emailHeader,
            callbackPassword:Object.keys(props.mandatoryFields).length!=0?props.mandatoryFields.callbackPassword:"",
            callbackUsername:Object.keys(props.mandatoryFields).length!=0?props.mandatoryFields.callbackUsername:"",
            partnerClientId:Object.keys(props.mandatoryFields).length!=0?props.mandatoryFields.partnerClientId:"",
        }
       

        const timeout = setTimeout(() => {
            window.location.href = "/integrations/"
          }, 4000);
        
       
    console.log("bodyFormdata==>",scheduleSettings)
        http.post(`/integrations/apps/client_config?appId=${appId}`, scheduleSettings)
            .then((response) => {
                console.log("response==>",response)
               props.closeDefaultSettings(false, {
                open: true,
                message: "Schedule Settings saved Sucessfully",
                variant: "success"
            })
            timeout()
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const onChangeCkeData=(data,name)=>{
        console.log("header data",data);
        console.log("header ",name)
        if(name==="header"){
            setScheduleSettingsObj((prevState) => {
                
                return ({
                    ...prevState,
                emailHeader:data
                })
        })
        }else{
            setScheduleSettingsObj((prevState) => {
                
                return ({
                    ...prevState,
                emailFooter:data
                })
        })
        }
    }

    return (
        <div style={{ paddingRight: '0px' }}>
            <Dialog
                disableEnforceFocus
                className={customDialogClass.dialogClass}
                onClose={handleClose}
                scroll="paper"
                TransitionComponent={Transition}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle style={{ padding: '0px' }}>
                    <Box style={{ padding: '23px 16px 24px 16px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #BED3E4' }}>
                        <Typography style={{ fontFamily: 'NotoSans-Medium', fontSize: '16px', color: '#001F52' }}>{props.selectedAppData.name} Integration </Typography>
                        <IconButton style={{ fontFamily: 'NotoSans-Regular', color: '#001F52', padding: '0px', borderRadius: '0px' }} onClick={handleClose}><CloseIcon /></IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent style={{ padding: '24px 32px' }} >
                    <Container maxWidth="false" style={{ alignItems: 'center', border: '1px solid #BED3E4', borderRadius: '4px', background: '#F8FAFC', padding: '24px 16px' }} >
                        <Typography style={{ fontFamily: 'NotoSans-Regular', fontSize: '14px', color: '#001F52', paddingBottom: '16px' }}>
                            Default settings for all assessments initiated from {props.selectedAppData.name} ATS
                        </Typography>
                        <Box style={{
                            padding: '10px 11px', border: '1px solid #BED3E4',
                            borderRadius: '4px', display: 'flex', alignItems: 'center', gap: '10px', flexDirection: 'column', background: '#fff'
                        }}>
                            <Grid container style={{ display: 'flex' }}>
                                <Grid item xs={12} >
                                    <Box style={{ display: 'flex', paddingTop: '10px' }}>
                                        <img height="20" width="20" src={MORE_APPS_CONFIG.lmsFullPath + '/avp.svg'} alt="error" />
                                        <Typography style={{ color: '#001F52', fontSize: '14px', fontFamily: 'NotoSans-Medium', padding: '0px 10px' }}>Advanced Visual Proctoring</Typography>
                                        <MsbTooltip interactive placement='top'
                                            title={<Typography style={{ fontSize: "12px", fontFamily: 'NotoSans-Regular' }}>
                                                You can watch candidate's live while taking the test or view
                                                recorded snapshots in the candidate's report on demand. Mettl marks instances of candidate's suspicious activities using it's Artificial Intelligence algorithm
                                            </Typography>} arrow>
                                            <img height="20" width="20" src={MORE_APPS_CONFIG.lmsFullPath + '/info.svg'} alt="error" />

                                        </MsbTooltip>
                                        <span style={{ marginLeft: 'auto', marginTop: '-10px', marginRight: '-5px' }}>
                                            <Switch
                                                name="visualProctoring"
                                                checked={defaultScheduleSettings.visualProctoring.mode==="OFF"?false:true}
                                                onChange={handleChange}
                                                color="primary"
                                                inputProps={{ 'aria-labelledby': 'switch-list-label', marginLeft: '50%' }}
                                                className={customDialogClass.materialUISwitch} />
                                        </span>
                                    </Box>
                                </Grid>
                            {defaultScheduleSettings.visualProctoring.mode!=="OFF"  && 
                             <div>
                                <Grid item xs={12} style={{ padding: '22px 0px 13px 30px', display: 'flex', alignItems: 'center' }}>
                                    <Checkbox className={customDialogClass.checkboxColor} name="candidateScreenCapture" color="primary"  onChange={handleChange}
                                        checked={defaultScheduleSettings.visualProctoring.options.candidateScreenCapture} style={{ padding: "0px", fontSize: '20px' }} />
                                    <Typography style={{ fontSize: "14px", paddingLeft: "12px", fontFamily: 'NotoSans-Medium', color: '#001F52' }}>Capture Candidate Screen </Typography>

                                </Grid>

                                <Grid item xs={12}  >
                                    <Box display="flex" style={{ height: '18px', padding: '0px 0px 13px 30px', alignItems: 'center', color: '#002C77', fontSize: '12px' }}>
                                        Runs On :
                                        <img height="18" width="108" src={MORE_APPS_CONFIG.lmsFullPath + '/Browser-logo.svg'} style={{ marginLeft: '11px' }} alt="error" />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} style={{ padding: '0px 0px 32px 30px' }}  >

                                    <IconButton style={{ background: 'transparent', borderRadius: '0px', padding: '0px 0px 13px 0px' }} onClick={() => { showMore ? setShowMore(false) : setShowMore(true) }}>
                                        {!showMore && <Typography style={{ fontFamily: 'NotoSans-Regular', fontSize: '14px', color: '#009DE0', textDecorationLine: 'underline' }}>More Settings</Typography>}
                                        {showMore && <Typography style={{ fontFamily: 'NotoSans-Regular', fontSize: '14px', color: '#009DE0', textDecorationLine: 'underline' }}>Less Settings</Typography>}
                                    </IconButton>
                                    {showMore && <Box>
                                        <Box style={{ padding: '0px 0px 13px 0px', display: 'flex', alignItems: 'center' }}>
                                            <Checkbox className={customDialogClass.checkboxColor} type="checkbox" name="candidateAuthorization" color="primary"
                                                checked={defaultScheduleSettings.visualProctoring.options.candidateAuthorization} onChange={handleChange}
                                                style={{ padding: "0px", fontSize: '20px' }} />
                                            <Typography style={{ fontSize: "14px", fontFamily: 'NotoSans-Medium', paddingLeft: "12px", color: '#001F52' }}>Candidate Authorization </Typography>
                                        </Box>
                                        <Box style={{ padding: '0px 0px 13px 0px', display: 'flex', alignItems: 'center' }}>
                                            <Checkbox className={customDialogClass.checkboxColor} type="checkbox" name="recordVideo" color="primary"
                                                checked={defaultScheduleSettings.visualProctoring.mode==="VIDEO"?true:false} onChange={handleChange}
                                                style={{ padding: "0px", fontSize: '20px' }} />
                                            <Typography style={{ fontSize: "14px", fontFamily: 'NotoSans-Medium', paddingLeft: "12px", color: '#001F52' }}>Store Recorded Video </Typography>
                                        </Box>
                                        <Box style={{ padding: '0px 0px 13px 0px', display: 'flex', alignItems: 'center' }}>
                                            <Checkbox className={customDialogClass.checkboxColor} type="checkbox" name="check360" color="primary"
                                                checked={defaultScheduleSettings.visualProctoring.options.check360} onChange={handleChange}
                                                style={{ padding: "0px", fontSize: '20px' }} />
                                            <Typography style={{ fontSize: "14px", fontFamily: 'NotoSans-Medium', paddingLeft: "12px", color: '#001F52' }}>Mandatory 360 Check</Typography>
                                        </Box>
                                        <Box style={{ padding: '0px 0px 13px 0px', display: 'flex', alignItems: 'center' }}>
                                            <Checkbox className={customDialogClass.checkboxColor} type="checkbox" name="mobileProctoring" color="primary"
                                                checked={defaultScheduleSettings.visualProctoring.options.mobileProctoring} onChange={handleChange}
                                                style={{ padding: "0px", fontSize: '20px' }} />
                                            <Typography style={{ fontSize: "14px", fontFamily: 'NotoSans-Medium', paddingLeft: "12px", color: '#001F52' }}>Mobile Camera Proctoring</Typography>
                                        </Box>
                                        <Box style={{ padding: '0px', display: 'flex', alignItems: 'center' }}>
                                            <Checkbox className={customDialogClass.checkboxColor} type="checkbox" name="secureBrowser" color="primary"
                                                checked={defaultScheduleSettings.secureBrowser.enabled} onChange={handleChange}
                                                style={{ padding: "0px", fontSize: '20px' }} />
                                            <Typography style={{ fontSize: "14px", fontFamily: 'NotoSans-Medium', paddingLeft: "12px", color: '#001F52' }}>Mettl Secure Browser</Typography>
                                        </Box>
                                    </Box>}

                                </Grid>
                                </div>}
                            </Grid>
                        </Box>
                        <Box style={{
                            padding: '16px 16px', border: '1px solid #BED3E4', marginTop: '16px',
                            borderRadius: '4px', display: 'flex', alignItems: 'center', gap: '10px', flexDirection: 'column', background: '#fff'
                        }}>
                            <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                                <Grid item xs={5} style={{ display: 'flex' }} >
                                    <img height="20" width="20" style={{ padding: '0px 10px' }} src={MORE_APPS_CONFIG.lmsFullPath + '/browsing_tolerance.svg'} alt="error" />
                                    <Typography style={{ color: '#001F52', fontSize: '14px', fontFamily: 'NotoSans-Medium' }}>Browsing Tolerance</Typography>
                                    <MsbTooltip interactive style={{ marginRight: '50%' }} placement='top'
                                        title={<Typography style={{ fontSize: "12px" }}>
                                            Not recommended for Upload Type questions in test
                                        </Typography>} arrow>
                                        <img height="20" width="20" style={{ paddingLeft: '10px' }} src={MORE_APPS_CONFIG.lmsFullPath + '/info.svg'} alt="error" />
                                    </MsbTooltip>
                                </Grid>
                                <Grid item xs={7} style={{ display: 'flex', alignItems: 'center',justifyContent:'end' }}>

                                    <FormControl style={{ border: 'solid 1px', borderColor: '#BED3E4', borderRadius: '4px', width: '248px' }}>
                                        <Select
                                            name="browsingToleranceCount"
                                            value={defaultScheduleSettings.webProctoring.enabled ? defaultScheduleSettings.webProctoring.count : ""}
                                             onChange={handleChange}
                                            onClose={onCloseSelectTag}
                                            onOpen={onOpenSelectTag}
                                            displayEmpty={true}
                                            disableUnderline
                                            inputProps={{ style: { border: 'solid 1px', borderColor: '#BED3E4' } }}
                                            style={{ padding: '5px 16px', color: '#8096B2', fontFamily: 'NotoSans-Regular' }}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem style={{ color: '#0B41AD', fontFamily: 'NotoSans-Regular', fontSize: '14px', padding: '10px 16px', justifyContent: 'space-between' }} value="">

                                                Not Applicable
                                                {!closeSelectTag && !defaultScheduleSettings.webProctoring?.enabled && <img height="20" width="13" style={{ marginRight: '16px' }} src={MORE_APPS_CONFIG.lmsFullPath + '/checkmark.svg'} alt="error" />}
                                            </MenuItem>
                                            <MenuItem style={{ color: '#0B41AD', fontFamily: 'NotoSans-Regular', fontSize: '14px', padding: '10px 16px', justifyContent: 'space-between' }} value={0}>No Warning
                                                {!closeSelectTag && defaultScheduleSettings.webProctoring?.enabled && defaultScheduleSettings.webProctoring.count === 0 && <img height="20" width="13" style={{ marginRight: '16px' }} src={MORE_APPS_CONFIG.lmsFullPath + '/checkmark.svg'} alt="error" />}
                                            </MenuItem>
                                            <MenuItem style={{ color: '#0B41AD', fontFamily: 'NotoSans-Regular', fontSize: '14px', padding: '10px 16px', justifyContent: 'space-between' }} value={1}>Very low (1 warning)
                                                {!closeSelectTag && defaultScheduleSettings.webProctoring.count === 1 && !isCustomBtCount && <img height="20" width="13" style={{ marginRight: '16px' }} src={MORE_APPS_CONFIG.lmsFullPath + '/checkmark.svg'} alt="error" />}
                                            </MenuItem>
                                            <MenuItem style={{ color: '#0B41AD', fontFamily: 'NotoSans-Regular', fontSize: '14px', padding: '10px 16px', justifyContent: 'space-between' }} value={2}>Low (2 warnings)
                                                {!closeSelectTag && defaultScheduleSettings.webProctoring.count === 2 && !isCustomBtCount && <img height="20" width="13" style={{ marginRight: '16px' }} src={MORE_APPS_CONFIG.lmsFullPath + '/checkmark.svg'} alt="error" />}
                                            </MenuItem>
                                            <MenuItem style={{ color: '#0B41AD', fontFamily: 'NotoSans-Regular', fontSize: '14px', padding: '10px 16px', justifyContent: 'space-between' }} value={4}>Medium (4 warnings)
                                                {!closeSelectTag && defaultScheduleSettings.webProctoring.count === 4 && !isCustomBtCount && <img height="20" width="13" style={{ marginRight: '16px' }} src={MORE_APPS_CONFIG.lmsFullPath + '/checkmark.svg'} alt="error" />}
                                            </MenuItem>
                                            <MenuItem style={{ color: '#0B41AD', fontFamily: 'NotoSans-Regular', fontSize: '14px', padding: '10px 16px', justifyContent: 'space-between' }} value={10}>High (10 warnings)
                                                {!closeSelectTag && defaultScheduleSettings.webProctoring.count === 10 && !isCustomBtCount && <img height="20" width="13" style={{ marginRight: '16px' }} src={MORE_APPS_CONFIG.lmsFullPath + '/checkmark.svg'} alt="error" />}
                                            </MenuItem>
                                            <MenuItem style={{ color: '#0B41AD', fontFamily: 'NotoSans-Regular', fontSize: '14px', padding: '10px 16px', justifyContent: 'space-between' }} value={!isCustomBtCount ? 11 : defaultScheduleSettings.webProctoring.count} >Custom Warning
                                                {!closeSelectTag && isCustomBtCount && <img height="20" width="13" style={{ marginRight: '16px' }} src={MORE_APPS_CONFIG.lmsFullPath + '/checkmark.svg'} alt="error" />}
                                            </MenuItem>
                                        </Select>

                                    </FormControl>
                                    {(showInput || isCustomBtCount) &&

                                    <Box component="span" width={50} ml={1}>
                                        <Input type="number" name="customWarning" value={defaultScheduleSettings.webProctoring.count}
                                             onChange={handleCustomWarningChange} 
                                            inputProps={{ min: 1, max: 99, style: { color: '#001F52', fontSize: '14px', fontFamily: 'NotoSans-Regular' } }} ></Input>
                                    </Box>

                                     }
                                    {(showRemainingWarnCheck || defaultScheduleSettings.webProctoring.count > 0) &&

                                    <React.Fragment>
                                        <Checkbox
                                             onChange={handleChange}
                                            checked={defaultScheduleSettings.webProctoring.showRemainingCounts}
                                            name="remainingCountEnabled"
                                            color="primary"
                                            inputProps={{
                                                'aria-label': 'remaining warnings checkbox',
                                            }}
                                        />
                                        <Typography style={{ display: 'flex', alignItems: 'center', fontFamily: 'NotoSans-Regular', color: '#001F52', fontSize: '14px' }} variant="body2">Show remaining</Typography>

                                    </React.Fragment>

                                     } 

                                </Grid>
                            </Grid>
                        </Box>
                        <Box style={{
                            padding: '16px 16px', border: '1px solid #BED3E4', marginTop: '16px',
                            borderRadius: '4px', display: 'flex', alignItems: 'center', gap: '10px', flexDirection: 'column', background: '#fff'
                        }}>
                            <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                     <img height="20" width="20" style={{ padding: '10px 10px 10px 5px' }} src={MORE_APPS_CONFIG.lmsFullPath + '/browsing_tolerance.svg'} alt="error" />
                                        <Typography style={{ color: '#001F52', fontSize: '14px', fontFamily: 'NotoSans-Medium' }}>Candidate Deadline</Typography>
                                        <MsbTooltip placement="top" style={{ marginRight: '50%' }} interactive title={
                                            <React.Fragment>
                                                <Typography style={{ fontSize: "12px", fontFamily: 'NotoSans-Regular' }}>Floating expiry limit for the candidate in hours</Typography>
                                               </React.Fragment>}
                                            arrow>
                                            {/* <InfoIcon aria-label="info" style={{ fontSize: 'medium', paddingLeft: '10px' }} color='action' /> */}
                                            <img height="20" width="20" style={{ padding: '10px 10px' }} src={MORE_APPS_CONFIG.lmsFullPath + '/info.svg'} alt="error" />
                                        </MsbTooltip>

                                        <span style={{ marginLeft: 'auto' }}>
                                            <Box style={{display:'flex',width:'248px',justifyContent:'end'}}>
                                        {/* <Input type="number" name="maxReattemptAllowed" min="1" disableUnderline inputProps={{
                                                                style: {
                                                                    width: '228px',
                                                                    border: 'solid 1px', borderColor: '#BED3E4', borderRadius: '4px', padding: '10px 16px', color: '#8096B2', fontSize: '14px', fontFamily: 'NotoSans-Regular'
                                                                }
                                                            }}
                                                                value={0} 
                                                            /> */}
                                        <TextField className={customDialogClass.inputBoxUnderline} id="standard-number"  name="testExpiryLimitInSec" type="number" value={scheduleSettingsObj.testExpiryLimitInSec < 1 ? "" : scheduleSettingsObj.testExpiryLimitInSec}  disableUnderline onChange={handleChange}
                                                                    inputProps={{ min: 1, style: { width:'248px', border: 'solid 1px', borderColor: '#BED3E4', borderRadius: '4px',
                                                                     padding: '10px 16px', fontSize: '14px', color: '#8096B2', fontFamily: 'NotoSans-Regular' } }}
                                                                     variant="standard" />

                                        </Box>
                                        </span>
                                    
                                </Grid>
                            </Grid>
                        </Box>

                        <Box style={{
                            padding: '16px 16px', border: '1px solid #BED3E4', marginTop: '16px',
                            borderRadius: '4px', display: 'flex', alignItems: 'center', gap: '10px', flexDirection: 'column', background: '#fff'
                        }}>
                            <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                                <Grid item xs={12} style={{  alignItems: 'center' }}>
                            
                                        <Typography style={{ color: '#001F52', fontSize: '14px', fontFamily: 'NotoSans-Medium',paddingBottom:'10px' }}>Email Template Custom Header</Typography>
                                        

                                        
                                            <Box style={{display:'flex',width:'248px'}}>
                                        <CustomEditor onChangeCkeData={onChangeCkeData} type={"header"} Data={scheduleSettingsObj.emailHeader}></CustomEditor>

                                        </Box>
                                        
                                    
                                </Grid>
                            </Grid>
                        </Box>

                        <Box style={{
                            padding: '16px 16px', border: '1px solid #BED3E4', marginTop: '16px',
                            borderRadius: '4px', display: 'flex', alignItems: 'center', gap: '10px', flexDirection: 'column', background: '#fff'
                        }}>
                            <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                                <Grid item xs={12} style={{  alignItems: 'center' }}>
                            
                                        <Typography style={{ color: '#001F52', fontSize: '14px', fontFamily: 'NotoSans-Medium',paddingBottom:'10px' }}>Email Template Custom Footer</Typography>
                                        

                                        
                                            <Box style={{display:'flex',width:'248px'}}>
                                        <CustomEditor onChangeCkeData={onChangeCkeData} type={"footer"} Data={scheduleSettingsObj.emailFooter}></CustomEditor>

                                        </Box>
                                        
                                    
                                </Grid>
                            </Grid>
                        </Box>

                      {optionalFields.length>0 &&
                        <Box style={{
                            padding: '20px 22px', border: '1px solid #BED3E4', marginTop: '16px',
                            borderRadius: '4px', display: 'flex', alignItems: 'center', gap: '10px', flexDirection: 'column', background: '#fff'
                        }}>
                            
                      {optionalFields.map((field) => (
                       <Box style={{padding:'0px',width:'100%',display:'flex',flexDirection:'column'}}>
                            <Typography style={{fontFamily:'NotoSans-Regular',fontSize:'12px',color:'#001F52',paddingBottom:'8px'}}> {field.label}</Typography>
                            <Box style={{width:'100%',height:'40px',flexDirection:'column',display:'flex'}}>
                            <Input type={field.type} name={field.name} disableUnderline inputProps={{style: { border: 'solid 1px', borderColor: '#BED3E4', borderRadius: '4px', padding: '10px 16px', color: '#8096B2', fontSize: '14px', fontFamily: 'NotoSans-Regular'}}}
                             value={field.table==="client"?clientTableData[field.name]:mappingTableData[field.name]}  onChange={event=>handleChangeDynamicFields(event,field.table)}/>
                             </Box>
                        </Box>))}
                        </Box>}
                    </Container>
                </DialogContent>
                <DialogActions style={{ display: 'flex', padding: '0px 16px 23px 16px', alignItems: 'center', gap: '12px', alignSelf: 'stretch', justifyContent: 'flex-end' }}>
                    <Box style={{padding:'16px 16px 0px 16px',display:'flex',justifyContent:'end',width:'100%',borderTop:'1px solid #BED3E4'}}>
                    <Button onClick={saveConfiguration}  style={{ fontFamily:'NotoSans-Regular',fontSize:'14px', padding: '10px 16px', gap: '8px', alignItems: 'center', display: 'flex', textTransform: 'capitalize',background:'#0B41AD',color:'#fff',border:'1px solid #0B41AD',borderRadius:'4px' }} autoFocus >
                        Save
                    </Button>
                    </Box>
                </DialogActions>
            </Dialog>

        </div>
    )
}


const MsbTooltip = withStyles((theme) => ({
    tooltip: {

        backgroundColor: '#002c77',
        color: '#fffff',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        padding: '8px 16px',
        alignItems: 'center',
        gap: '8px'
    },
    arrow: {
        color: '#002c77'
    }
}))(Tooltip);