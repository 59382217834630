import React, { useEffect, useState } from 'react';
import { Box,Checkbox, Button, Container, IconButton, Input, Tooltip, Typography } from '@material-ui/core';
import MORE_APPS_CONFIG
 from '../../moreApps';
 import Paper from '@material-ui/core/Paper';
export default function IntegrationRequest(props) {

    const integrationName=props.IntegrationName   
    const  email="mettl-delivery@mercer.com"
    const subject=`Requesting Mercer | Mettl integration with  ${integrationName}`
    const body=`Hi Mercer | Mettl Client Delivery Team, %0D%0A%0D%0AI am looking to enable ${integrationName} integration but I donâ€™t seem to find the integration. Kindly help with the same.%0D%0A%0D%0APrimary Login Email ID â€“ %0D%0A%0D%0ACompany Name -`
       
    console.log("body==>",body)

    useEffect(()=>{

    })

    return(
        <Container component={Paper} maxWidth='true'  style={{display: 'flex',alignItems: 'center',padding:'105px 0px 105px 0px',borderRadius:'0px',justifyContent:'center',boxShadow:'none',borderBottom: '1px solid #BED3E4',
        background: '#FFF'}}>
            <Box style={{display:'flex',flexDirection:'column',gap:'8px',alignItems:'center'}}>
                
                <Box style={{display:'inline-flex',flexDirection:'column',alignItems:'center',gap:'24px'}}>
                <img  src={MORE_APPS_CONFIG.lmsFullPath + '/images/integration/mac.svg'} alt="help" />
                </Box>
                <Box style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                    <Typography style={{color:'#001F52',fontFamily:'NotoSans-Regular',fontSize:'14px',fontWeight:'500',lineHeight:'24px'}}>
                    Couldn't find the system you are looking for
                    </Typography>
                    <Typography style={{color:'#4E6287',fontFamily:'NotoSans-Regular',fontSize:'12px',fontWeight:'500',lineHeight:'20px'}}>
                    Click the below button to let us know/request for a consultation
                    </Typography>
                </Box>
                <Box  style={{display:'flex',flexDirection:'column',paddingTop:'26px',alignItems:'center'}}>
                <Button variant="outlined" size="medium" href={`mailto:${email}?subject=${subject}&body=${body}`}
                style={{ fontSize: '14px', fontFamily: 'NotoSans-Regular', textTransform: 'capitalize', borderColor: '#002c77', padding:'8px 12px',backgroundColor:'#0B41AD' }}>
                   
                   <Typography style={{ fontFamily: 'NotoSans-Regular', fontSize: '14px',color:'#fff' }}>Request Now</Typography>
                </Button>
                </Box>
            </Box>
        </Container>

    );

}
