import React, { useEffect } from 'react'

import {
    Box, Card, CardContent, Typography, Icon, IconButton, Divider, Container, CardHeader, Link, CircularProgress, Chip, Tooltip, Button
} from '@material-ui/core'

import SearchForm from './searchForm'
import SupportTable from './supportTable'
import SupportDashboard from './supportDashboard'
import DialogComponent from '../shared/dialogComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import http from 'axios';
import CustomizedSnackbar from '../shared/CustomizedSnackbar'
import SupportTableFilter from './supportTableFilter'
import Error from '../shared/Error';

export const CandidateStatusMap = new Map([
    ["LMS_TEST_START_EVENT", { published: "No", status: "Not Taken", color: "grey" }],
    ["METTL_TEST_STARTED", { published: "No", status: "In Progress", color: "orange" }],
    ["METTL_TEST_FINISHED", { published: "No", status: "In Progress", color: "orange" }],
    ["METTL_TEST_EXPIRED", { published: "No", status: "Expired", color: "red" }],
    ["METTL_TEST_GRADED", { published: "No", status: "Completed", color: "green" }],
    ["LMS_RESULT_PUSH_EVENT", { published: "Yes", status: "Completed", color: "green" }],
]);

const filterIncludes = (rows, id, filterValue) => {
    return rows.filter(row => {
        return filterValue.includes(row.original.status);
    })
}

export default function LTISupport(props) {

    const [applicantData, setApplicantData] = React.useState(undefined);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [openDialog, setOpenDialog] = React.useState(false);
    const [loggedIn, setLoggedIn] = React.useState(true);
    const [pageInit, setPageInit] = React.useState(false);

    const [selectedApplicant, setSelectedApplicant] = React.useState({
        selectedIds: [],
        action: undefined
    })

    const [isLoading, setIsLoading] = React.useState(false);

    const [snackbarProps, setSnackbarProps] = React.useState({ open: false, message: "", variant: "" })

    useEffect(() => {
        document.title = 'Mettl Lms : Support';
        (async function idAuthenticated() {
            try {
                let response = await http.get("/lti/authenticated");
                if (response) { setLoggedIn(true) }
            } catch (err) {
                console.log(err);
                setLoggedIn(false)
            } finally {
                setPageInit(true);
            }
        })();

    }, [])

    const formatDate = (timeStamp) => {
        let currentDate = new Date(timeStamp);
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let formattedDate = currentDate.getDate() + " " + months[currentDate.getMonth()] + " '" + currentDate.getFullYear().toString().substr(2, 2)
        return (formattedDate)
    }

    const rescheduleApplicant = (data) => {
        setIsLoading(true);
        let applicantActivityList = data.map(d => d.applicantActivityId)

        http.post("/lti/support/reschedule-applicant", applicantActivityList)
            .then((response) => {
                setIsLoading(false);
                setSnackbarProps({ open: true, message: response.data.message, variant: response.data.success ? "success" : "error" })
            })
            .catch((error) => {
                setIsLoading(false);
                if (error.response.status == 401) {
                    setLoggedIn(false);
                    return;
                }
                setSnackbarProps({ open: true, message: error.response.data.message, variant: "error" })
            });
    }

    const lmsGradePush = (data) => {
        setIsLoading(true);
        let applicantActivityList = data.map(d => d.applicantActivityId)

        http.post("/lti/support/grade-push", applicantActivityList)
            .then((response) => {
                setIsLoading(false);
                setSnackbarProps({ open: true, message: response.data.message, variant: response.data.success ? "success" : "error" })
            })
            .catch((error) => {
                setIsLoading(false);
                if (error.response.status == 401) {
                    setLoggedIn(false);
                    return;
                }
                setSnackbarProps({ open: true, message: error.response.data.message, variant: "error" })
            });
    }

    const supportDashboardData = (data) => {
        setIsLoading(true);
        http.post("/lti/support/support-dashboard", data)
            .then((response) => {
                setApplicantData(response.data)
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setSearchTerm("");
                if (error.response.status == 401) {
                    setLoggedIn(false);
                    return;
                }
                setSnackbarProps({ open: true, message: error.response.data.message, variant: "error" })
            });

    }

    const handleSearch = (query, queryType) => {

        if (query !== "") {
            setSearchTerm(query);
            let data = {};

            switch (queryType) {
                case "Email Id":
                    data["email"] = query;
                    break;
                case "Course":
                    data["course"] = query;
                    break;
                case "Activity":
                    data["activity"] = query;
                    break;
                case "Published to LMS?":
                    data["isPublished"] = query;
                    break;
            }

            supportDashboardData(data);

        } else {
            setSnackbarProps({ open: true, message: "Input cannot be empty", variant: "error" })
        }
    }

    const handleSelectedIds = (data, action) => {
        let applicantList = [];
        let allowAction = true;

        data.some(row => {
            if (["METTL_TEST_EXPIRED", "METTL_TEST_GRADED", "LMS_RESULT_PUSH_EVENT"].indexOf(row.original.status) > -1) {
                applicantList.push(row.original);
            }
        })

        if (applicantList.length > 0) {
            setSelectedApplicant({ selectedIds: applicantList, action: action });
            setOpenDialog(true);
        } else {
            setSnackbarProps({ open: true, message: "Action not allowed for Selected Applicant(s)", variant: "error" })
        }
    }

    const handleDialogClose = (isSent) => {
        if (isSent) {
            if (selectedApplicant.action === "publish") {
                lmsGradePush(selectedApplicant.selectedIds);
            }
            if (selectedApplicant.action === "reschedule") {
                rescheduleApplicant(selectedApplicant.selectedIds)
            }
        } else {
            setSelectedApplicant({ selectedIds: [], action: undefined });
        }
        setOpenDialog(false);
    }

    const handleCloseSnackbar = () => {
        setSnackbarProps({ open: false, message: "", variant: "" })
    }

    const columns = React.useMemo(() => [
        {
            Header: "Email",
            accessor: "email",
            disableFilters: true,
        },
        {
            Header: 'Course',
            accessor: 'course',
            Filter: SupportTableFilter,
            filter: 'equals'
        },
        {
            Header: 'Activity',
            accessor: 'activity',
            Filter: SupportTableFilter,
            filter: 'equals'
        },
        {
            Header: 'Assesment Name',
            accessor: 'assesmentName',
            Filter: SupportTableFilter,
            filter: 'equals',
            minWidth: "70px"

        },
        {
            Header: 'Attempts',
            accessor: 'attempt',
            disableFilters: true,
        },
        {
            Header: 'Date',
            accessor: 'timeStamp',
            disableFilters: true,
            Cell: cellData => formatDate(cellData.cell.value)
        },
        {
            Header: 'Score',
            id: 'score',
            accessor: d => d.score ? Number(d.score * 100).toFixed(2) : null,
            sortMethod: (a, b) => Number(a) - Number(b),
            disableFilters: true,
            Cell: cellData => cellData.cell.value ? cellData.cell.value + "%" : ""
        },
        {
            Header: 'Status',
            accessor: 'status',
            Filter: SupportTableFilter,
            filter: filterIncludes,
            Cell: cellData => <React.Fragment>
                <Icon style={{ fontSize: "11px", color: CandidateStatusMap.get(cellData.cell.value).color }}>fiber_manual_record</Icon>
                <Typography component="span"> {CandidateStatusMap.get(cellData.cell.value).status}</Typography>
            </React.Fragment>
        },
        {
            Header: 'Published to LMS?',
            accessor: 'lmsPublished',
            sortType: (rowA, rowB) => { return rowA.values.lmsPublished > rowB.values.lmsPublished ? 1 : -1 },
            disableFilters: true,
            Cell: cellData => cellData.cell.value === true ?
                'Yes' :
                <React.Fragment>{'No '}
                    {cellData.row.original.status === "METTL_TEST_GRADED" || cellData.row.original.status === "METTL_TEST_EXPIRED" ?
                        <Link href="#" onClick={e => handleSelectedIds([cellData.row], "publish")}>(Retry)</Link> : ""}
                </React.Fragment>
        },
        {
            // Header: 'Actions',
            Header: 'Report',
            disableSortBy: true,
            disableFilters: true,
            Cell: cellData =>
                <Box display="center" justifyContent="space-around">
                    {/* <Tooltip title="Reattempt" arrow>
                        <span>
                            <IconButton onClick={e => handleSelectedIds([cellData.row], "reschedule")} size="small"
                                disabled={["LMS_TEST_START_EVENT", "METTL_TEST_STARTED", "METTL_TEST_FINISHED"].indexOf(cellData.row.original.status) >= 0}>
                                <FontAwesomeIcon icon={faSync} />
                            </IconButton>
                        </span>
                    </Tooltip> */}
                    <Tooltip title="View Report" arrow>
                        <span>
                            <IconButton size="small" disabled={!Boolean(cellData.row.original.reportUrl)}>
                                <Link underline="none" color="inherit" target="_blank" href={cellData.row.original.reportUrl}><FontAwesomeIcon icon={faFileAlt} /></Link>
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
        },
    ], []);

    if (pageInit && !loggedIn) {
        return (<div><Error status="401"></Error></div>)
    } else if (pageInit && loggedIn) {
        return (
            <Container maxWidth="lg" >
                <Box mt={6}>
                    <Card>
                        <CardHeader disableTypography title={
                            <Box display="flex" alignItems="center">
                                <Typography variant="h5" color="textPrimary">LTI Support</Typography>
                                <Box ml="auto">
                                    <SearchForm isLoading={isLoading} searchQuery={handleSearch}></SearchForm>
                                </Box>
                            </Box>
                        }>
                        </CardHeader>
                        <Divider variant="middle" />
                        <CardContent style={{ padding: 0 }} >
                            <Box mb={2}>
                                {searchTerm === "" && !isLoading && <SupportDashboard />}

                                {searchTerm !== "" && applicantData && !isLoading &&
                                    <SupportTable handleSelectedRows={handleSelectedIds} columns={columns} data={applicantData} />
                                }
                                {isLoading &&
                                    <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
                                        <CircularProgress />
                                    </Box>
                                }
                            </Box>
                        </CardContent>
                    </Card>
                </Box>

                {openDialog &&
                    <DialogComponent
                        dialogTitle={selectedApplicant.action === "publish" ? "Send Results Back to LMS" : "Regenerate Test Link"}
                        dialogContent={
                            <Box>
                                {selectedApplicant.action === "publish" ?
                                    <span>Are you sure you want to resend the result for selected ID's back to LMS ?</span> :
                                    <span>Are you sure you want to regenerate test link for selected ID's ?</span>
                                }
                                <Box display="flex" flexWrap="wrap" mt={1}>
                                    {selectedApplicant.selectedIds.map((data, i) => (
                                        <Box key={i} m={0.5}>
                                            <Chip className="bg-light-gray" size="small" label={<Typography>{data.email}</Typography>} variant="outlined" />
                                        </Box>)
                                    )}
                                </Box>
                            </Box>
                        }
                        dialogButtonText={selectedApplicant.action === "publish" ? "Publish" : "Reschedule"}
                        dialogButtonColor="primary"
                        open={openDialog} onClose={handleDialogClose}
                    ></DialogComponent>}

                {snackbarProps.open &&
                    <CustomizedSnackbar onClose={handleCloseSnackbar} open={snackbarProps.open} variant={snackbarProps.variant} message={snackbarProps.message}></CustomizedSnackbar>}
            </Container>
        )
    } else {
        return (<div></div>)
    }


}