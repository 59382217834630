import React, { useEffect } from 'react'
import {useLocation, useParams} from 'react-router-dom'
import axios from 'axios'
import APP_CONFIG from '../config.json';
import {
    Box, Container, Button
} from '@material-ui/core'
import { sanitiseInput } from '../utils/helperFn';


export default function Authorize(props){
        const location = useLocation();
        const {clientId} = useParams();
        console.log("location pathname",location.pathname);
        const getLeverAuthorize = ()=>{
            console.log("env ",process.env);
            console.log("APP_CONFIG", APP_CONFIG);
            let authURI = `https://sandbox-lever.auth0.com/authorize?client_id=${APP_CONFIG.clientId}&redirect_uri=${APP_CONFIG.redirectCallBackUrl}&state=${APP_CONFIG.state}&response_type=code&scope=${APP_CONFIG.scope}&prompt=consent&audience=${APP_CONFIG.baseUrl}`;
            let encodedAuthURI = encodeURI(authURI);

            //window.location = encodedAuthURI;
            axios.get("/lever/getLeverAuthUrl?clientId="+clientId).then((response)=>{
                console.log(response);
                let data = response.data;
                window.location = sanitiseInput(data.redirectUrl);
            });
        };

    return (
        <Container className="authorize-box">    
            <Box px={5} py={2} sx={{
                    width: 300,
                    height: 'auto',
                    minHeight:100,
                    marginTop: -75,
                    borderBottom: '4px solid #002C77',
                    boxShadow: '0px 0px 5px grey',
                    borderRadius: 5,
                    backgroundColor: 'white',
                    textAlign : 'center'
                }
            }>
                    <Box component="span" sx={{color:'#002C77', fontWeight:800}}>Authorize your Credentials</Box>
                    <br></br>
                    <small>Please authorize via Lever using the button below</small>
                    <Button variant="contained" className="authorize-button bg-mettl-blue" mx={5} onClick={getLeverAuthorize}>Authorize via Lever</Button>
            </Box>
        </Container>
        )
}